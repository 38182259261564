import styles from './contactUs.module.css';
import Navigation from '../Navigation';
import Footer from '../Footer';
import { SocialIcon } from 'react-social-icons';
import MyForm from '../contactForm/ContactForm';
import { Helmet } from 'react-helmet';

const ContactUs = () => {
    
    return(
     <div>
        <Helmet>
        <title>Contact Aspire Holidays | Get in Touch for Inquiries and Assistance</title>
        <meta name="description" content="Contact Aspire Holidays for inquiries, feedback, or assistance. Reach out to our team to plan your next vacation. Enter your details and let us help you create unforgettable travel experiences." />
        <meta name="keywords" content="Contact Aspire Holidays, Inquiries, Feedback, Assistance, Plan Vacation, Travel Experiences" />
      </Helmet>
        <Navigation path='contact_us'/>
        <div className={styles.div001}>
            <div className={styles.div0011}>
            <div className={styles.div0012}>
                <h1>Contact Us On</h1>
                
                   <div className={styles.myform}>
                    <MyForm/>
                   </div>
                <div className={styles.div002}>
                    <div className={styles.div003}>
                    <SocialIcon url="https://www.instagram.com/aspireholidaysuk/?igshid=YmMyMTA2M2Y%3D" fgColor="transparent" bgColor="#001c28" style={{ height: 35, width: 35 }}/>
                       
                    </div>
                    <div className={styles.div003}>
                    <SocialIcon url="https://www.linkedin.com/company/aspire-holidays-ltd/" fgColor="transparent" bgColor="#001c28" style={{ height: 35, width: 35 }}/>
                     
                    </div>
                    <div className={styles.div003}>
                    <SocialIcon url="https://facebook.com/" fgColor="transparent" bgColor="#001c28" style={{ height: 35, width: 35 }}/>
                      
                    </div>
                    {/* <div className={styles.div003}>
                    <SocialIcon url="https://google.com/in/jaketrent" fgColor="transparent" bgColor="#001c28" style={{ height: 35, width: 35 }}/>
                      
                    </div> */}
                </div> 
                <div className={styles.div004}>
                <h2>Talk to Our Customer Care Executive</h2> 
                <a href="tel:+447428262534"><button>Call Now</button></a>
                </div>
            </div>
        </div>
        </div>
        <Footer /> 
     </div>

    )
};

export default ContactUs;
