import React, { useEffect } from 'react';

const TrustpilotWidget = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="trustpilot-widget" data-locale="en-GB" data-template-id="56278e9abfbbba0bdcd568bc" data-businessunit-id="66093c29baae927d36217797" data-style-height="52px" data-style-width="100%">
      <a href="https://uk.trustpilot.com/review/aspireholidaysltd.com" target="_blank" rel="noopener">Trustpilot</a>
    </div>
  );
};

export default TrustpilotWidget;
