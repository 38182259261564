import React from 'react';
import styles from './WhatsAppButton.module.css';
import { FaWhatsapp } from 'react-icons/fa';

const WhatsAppButton = () => {
    const preFilledMessage = encodeURIComponent("Hi Aspire Holidays, Iam looking for .....");

  // WhatsApp link with pre-filled message
  const whatsappLink = `https://wa.me/447428262534?text=${preFilledMessage}`;

  return (
    
    <div className={styles.whatsapp}>
        <a
      href={whatsappLink}
      target="_blank"
      rel="noopener noreferrer"
    >
      <FaWhatsapp className={styles.whatsappicon}style={{ marginRight: '5px' ,color:'green'}} />
    </a>
        </div>
  );
};

export default WhatsAppButton;
